import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box, Button, Grid, IconButton } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import doctorAPI from "../../../../utils/doctorAPI";
import { FeAutocomplete, FeTextField } from "../../../FormElements";
import FeDesktopDatePicker from "../../../FormElements/FeDesktopDatePicker";
import { updateObjectValue } from "../../../../utils/helper";

const StatOptions = ["Stable", "Progressed", "Censored"];
const SurvivalOptions = ["Alive", "Dead", "Censored"];

const StatForm = ({ onStateChange, editedObj, diagDate, updatedObj }) => {
  const [obj, setObj] = useState({
    disease_progression_status: "",
    disease_progression_status_date: null,
    survival_status: "",
    survival_status_date: null,
    clinical_response_rate: "",
    pathological_response_rate: "",
    radiological_response_rate: "",
    // response_rate_status_date: null,
  });

  useEffect(() => {
    onStateChange(obj);
  }, [obj, onStateChange]);
  useEffect(() => {
    if (updatedObj) {
      const u = updateObjectValue(obj, updatedObj);
      setTimeout(() => {
        setObj(u);
      }, 100);
    }
  }, [updatedObj]);
  useEffect(() => {
    if (editedObj) {
      setObj(Object.assign({}, obj, editedObj));
    }
  }, [editedObj]);
  const handleChangeInput = (name, value) => {
    setObj({
      ...obj,
      [name]: value,
    });
  };

  return (
    <>
      {" "}
      <Grid item xs={12} md={12}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            Disease Progression Status
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} md={6}>
        <Box mb={2}>
          <FeAutocomplete
            //  disabled={editedObj}
            options={StatOptions}
            value={obj.disease_progression_status}
            id="Options"
            label="Options"
            onChange={(e, v) => {
              handleChangeInput("disease_progression_status", v);
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={6} md={6}>
        <Box
          mb={2}
          sx={{
            "& .MuiFormControl-root": {
              width: "100%",
            },
          }}
        >
          <FeDesktopDatePicker
            //  disabled={editedObj}
            label="Date"
            inputFormat="DD/MM/YYYY"
            value={obj.disease_progression_status_date}
            onChange={(val) => {
              handleChangeInput(
                "disease_progression_status_date",

                dayjs(val).format("YYYY-MM-DD")
              );
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            Survival Status
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} md={6}>
        <Box mb={2}>
          <FeAutocomplete
            //  disabled={editedObj}
            options={SurvivalOptions}
            value={obj.survival_status}
            id="Options"
            label="Options"
            onChange={(e, v) => {
              handleChangeInput("survival_status", v);
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={6} md={6}>
        <Box
          mb={2}
          sx={{
            "& .MuiFormControl-root": {
              width: "100%",
            },
          }}
        >
          <FeDesktopDatePicker
            //  disabled={editedObj}
            label="Date"
            inputFormat="DD/MM/YYYY"
            value={obj.survival_status_date}
            onChange={(val) => {
              handleChangeInput(
                "survival_status_date",

                dayjs(val).format("YYYY-MM-DD")
              );
            }}
          />
        </Box>
      </Grid>
      {/* <Grid item xs={6} md={6}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            Clinical Response Rate
          </Grid>
        </Grid>

        <Box mb={2}>
          <FeAutocomplete
          //  disabled={editedObj}
            options={clinicals.map((o) => o.name)}
            value={obj.response_rate_status}
            id="Options"
            label="Options"
            onChange={(e, v) => {
              handleChangeInput("clinical_response_rate", v);
            }}
          />
        </Box>
      </Grid> */}
      {/* <Grid item xs={6} md={6}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            Pathological Response Rate
          </Grid>
        </Grid>
        <Box mb={2}>
          <FeAutocomplete
          //  disabled={editedObj}
            options={pathologicals.map((o) => o.name)}
            value={obj.pathological_response_rate}
            id="Options"
            label="Options"
            onChange={(e, v) => {
              handleChangeInput("pathological_response_rate", v);
            }}
          />
        </Box>
      </Grid> */}
      {/* <Grid item xs={6} md={6}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            Radiological Response Rate
          </Grid>
        </Grid>
        <Box mb={2}>
          <FeAutocomplete
          //  disabled={editedObj}
            options={radiologicals.map((o) => o.name)}
            value={obj.radiological_response_rate}
            id="Options"
            label="Options"
            onChange={(e, v) => {
              handleChangeInput("radiological_response_rate", v);
            }}
          />
        </Box>
      </Grid> */}
      {/* <Grid item xs={6} md={6}>
        <Box
          mb={2}
          sx={{
            "& .MuiFormControl-root": {
              width: "100%",
            },
          }}
        >
          <FeDesktopDatePicker
          //  disabled={editedObj}
            label="Date"
            inputFormat="DD/MM/YYYY"
            value={obj.response_rate_status_date}
            onChange={(val) => {
              handleChangeInput(
                "response_rate_status_date",

                dayjs(val).format("YYYY-MM-DD")
              );
            }}
          />
        </Box>
      </Grid> */}
      <Grid item xs={6} md={6}>
        <Box
          mb={2}
          sx={{
            border: "1px solid rgba(217, 198, 198, 0.87)",
            padding: "15px",
          }}
        >
          PFS -{" "}
          {obj.disease_progression_status_date && diagDate && (
            <>
              {dayjs(obj.disease_progression_status_date)
                .diff(dayjs(diagDate), "month", true)
                .toFixed(2)}
              {""} Months
            </>
          )}
        </Box>
      </Grid>
      <Grid item xs={6} md={6}>
        <Box
          mb={2}
          sx={{
            border: "1px solid rgba(217, 198, 198, 0.87)",
            padding: "15px",
            "& .MuiFormControl-root": {
              width: "100%",
            },
          }}
        >
          Overall Survival (OS) -{" "}
          {obj.survival_status_date && diagDate && (
            <>
              {dayjs(obj.survival_status_date)
                .diff(dayjs(diagDate), "month", true)
                .toFixed(2)}
              {""} Months
            </>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default StatForm;
