import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import adminAPi from "../../../utils/adminAPi";
import dayjs from "dayjs";
import { isEmpty } from "lodash";

const Details = () => {
  const [obj, setObj] = useState({});
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, []);
  async function getDetails(id) {
    const { data } = await adminAPi.get(
      `admin/patients-with-observation/${id}`
    );
    setObj(data?.data);
  }
  const renderFollowedBy = (arr) => {
    if (arr && arr.length > 0) {
      let followed_byArr = [];
      if (arr.length > 0) {
        arr.forEach((element) => {
          let followObj = {
            selected: !isEmpty(element)
              ? element.followed_by.map((o) => o.value)
              : [],
            cycle_no: parseInt(
              !isEmpty(element)
                ? element.followed_by[element.followed_by.length - 1].cycle_no
                : null
            ),
          };

          followed_byArr.push(followObj);
        });
      }
      return (
        <>
          {followed_byArr.map((entry, index) => {
            const { selected, cycle_no } = entry;

            return (
              <span key={index}>
                {""} Followed by {selected.join(", ")} {cycle_no} Cycle
              </span>
            );
          })}
        </>
      );
    } else return <></>;
  };

  const renderProtocols = (row) => {
    if (row.length > 0) {
      const chemArr = [...row];
      const primary = chemArr.filter((o) => o.type === "primary");
      const followed = chemArr.filter((o) => o.type === "followed_by");
      let chemotherapy_protocols = [];
      let followed_by = [];
      if (primary.length > 0) {
        primary.map((item, i) => {
          item.chemotherapy_protocol_details.map((child, k) => {
            let obj = {
              cycle_no: item.cycle_no,
              value: child.value,
            };
            chemotherapy_protocols.push(obj);
          });
        });
      }
      if (followed.length > 0) {
        followed.map((item, i) => {
          item.chemotherapy_protocol_details.map((child, k) => {
            let obj = {
              cycle_no: item.cycle_no,
              value: child.value,
            };
            followed_by.push(obj);
          });
        });
      }

      let arr = [
        {
          chemotherapy_protocols,
        },
        {
          followed_by,
        },
      ];

      return (
        <h4>
          {" "}
          {arr
            .filter((o) => o.chemotherapy_protocols)[0]
            .chemotherapy_protocols.map((item, i) => item.value)
            .join(" + ")}{" "}
          {
            arr.filter((o) => o.chemotherapy_protocols)[0]
              .chemotherapy_protocols[
              arr.filter((o) => o.chemotherapy_protocols)[0]
                .chemotherapy_protocols.length - 1
            ].cycle_no
          }{" "}
          cycle{" "}
          <span>
            {arr.filter((o) => o.followed_by).length > 0 &&
              arr.filter((o) => o.followed_by)[0].followed_by.length > 0 &&
              arr.filter((o) => o.followed_by)[0].followed_by[0].value !==
                "" && <>{renderFollowedBy(arr.filter((o) => o.followed_by))}</>}
          </span>
        </h4>
      );
    }
  };
  return (
    <Box>
      <Box sx={{ border: "1px solid #f1f1f1" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box>
              Patient name:{" "}
              <span style={{ fontWeight: "bold" }}>{obj?.name}</span>{" "}
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              Registration No.{" "}
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {obj?.patient_observations?.registration_no}
              </span>{" "}
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              Center:{" "}
              <span style={{ fontWeight: "bold" }}>
                {obj?.patient_observations?.center?.name}
              </span>{" "}
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              Consultant:{" "}
              <span style={{ fontWeight: "bold" }}>
                {obj?.patient_observations?.doctor?.name}
              </span>{" "}
            </Box>
          </Grid>
        </Grid>
        <Grid container mt={1} spacing={2}>
          <Grid item xs={12} md={12}>
            <h3>Diagnosis:</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Diagnosis Disease Group</TableCell>
                    <TableCell>Diagnosis Subgroup</TableCell>
                    <TableCell>Primary site</TableCell>
                    <TableCell>Metastatic Site</TableCell>
                    <TableCell>Laterality</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.diagnosis?.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>{row.diagnosis_disease_group}</TableCell>
                        <TableCell>{row.diagnosis_subgroup}</TableCell>
                        <TableCell>{row.diagnosis_primary_site}</TableCell>
                        <TableCell>
                          {row.diagnosis_metastatic_sites?.length > 0 &&
                            row.diagnosis_metastatic_sites.map((item, i) => (
                              <p
                                style={{
                                  borderBottom:
                                    "1px solid rgba(224, 224, 224, 1)",
                                  paddingBottom: "10px",
                                }}
                                key={i}
                              >
                                {" "}
                                {item.value}
                              </p>
                            ))}
                        </TableCell>
                        {/* <TableCell>
                          {row.diagnosis_metastatic_sites?.length > 0 &&
                            row.diagnosis_metastatic_sites
                              .map((item, i) => item.value)
                              .join(" , ")}
                        </TableCell> */}
                        <TableCell>{row.laterality}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={7}>
            <h3>Histopathology:</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Histopathology Report</TableCell>
                    <TableCell>Grade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.histopathology?.map(
                    (row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                          </TableCell>

                          <TableCell>
                            {row.histopathologies?.length > 0 &&
                              row.histopathologies.map((item, i) => (
                                <p
                                  style={{
                                    borderBottom:
                                      "1px solid rgba(224, 224, 224, 1)",
                                    paddingBottom: "10px",
                                  }}
                                  key={i}
                                >
                                  {" "}
                                  {item.detail}
                                </p>
                              ))}
                          </TableCell>
                          {/* {row.histopathologies?.length > 0 &&
                              row.histopathologies
                                .map((item, i) => item.detail)
                                .join(" , ")} */}
                          <TableCell>{row.grade}</TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={5}>
            <h3>Clock Position/mammogram Based :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Side</TableCell>
                    <TableCell>Position</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.clock_positions?.map(
                    (row, index) => (
                      <React.Fragment key={index}>
                        {row.map((data) => (
                          <TableRow key={data.id}>
                            <TableCell>
                              {" "}
                              {data.date &&
                                dayjs(data.date).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell>{data.side}</TableCell>
                            <TableCell>{data.position}</TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <h3>Hormone receptor Status :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>ER</TableCell>
                    <TableCell>PR</TableCell>
                    <TableCell>HER2</TableCell>
                    <TableCell>Ki67</TableCell>
                    <TableCell>Diagnosis Group</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.diagnosis_groups?.map(
                    (row, index) => (
                      <React.Fragment key={index}>
                        {row.map((data) => (
                          <TableRow key={data.id}>
                            <TableCell>
                              {" "}
                              {data.date &&
                                dayjs(data.date).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell>{data.er}</TableCell>
                            <TableCell>{data.pr}</TableCell>
                            <TableCell>{data.her2}</TableCell>
                            <TableCell>{data.ki67}</TableCell>
                            <TableCell>{data.result}</TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <h3>IHC :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>ER</TableCell>
                    <TableCell>PR</TableCell>
                    <TableCell>HER2</TableCell>
                    <TableCell>Fish</TableCell>
                    <TableCell>Synaptophysin</TableCell>
                    <TableCell>Chromogranin</TableCell>
                    <TableCell>Others</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.ihcs?.map((row, index) => (
                    <React.Fragment key={index}>
                      {row.map((data) => (
                        <TableRow key={data.id}>
                          <TableCell>
                            {" "}
                            {data.date && dayjs(data.date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>{data.er}</TableCell>
                          <TableCell>{data.pr}</TableCell>
                          <TableCell>{data.her2}</TableCell>
                          <TableCell>{data.fish}</TableCell>
                          <TableCell>{data.synaptophysin}</TableCell>
                          <TableCell>{data.chromogranin}</TableCell>
                          <TableCell>{data.others}</TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid item xs={12} md={12}>
              <h3>TNM Staging (Clinical) :</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>T</TableCell>
                      <TableCell>N</TableCell>
                      <TableCell>M</TableCell>
                      <TableCell>Stage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {obj?.patient_observations?.staging_clinicals?.map(
                      (row, index) => (
                        <React.Fragment key={index}>
                          {row.map((data) => (
                            <TableRow key={data.id}>
                              <TableCell>
                                {" "}
                                {data.date &&
                                  dayjs(data.date).format("DD-MM-YYYY")}
                              </TableCell>
                              <TableCell>{data.t}</TableCell>
                              <TableCell>{data.n}</TableCell>
                              <TableCell>{data.m}</TableCell>
                              <TableCell>{data.result}</TableCell>
                            </TableRow>
                          ))}
                        </React.Fragment>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={12}>
              <h3>TNM Staging (Pathological) :</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>T</TableCell>
                      <TableCell>N</TableCell>
                      <TableCell>M</TableCell>
                      <TableCell>Stage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {obj?.patient_observations?.staging_pathologicals?.map(
                      (row, index) => (
                        <React.Fragment key={index}>
                          {row.map((data) => (
                            <TableRow key={data.id}>
                              <TableCell>
                                {" "}
                                {data.date &&
                                  dayjs(data.date).format("DD-MM-YYYY")}
                              </TableCell>
                              <TableCell>{data.t}</TableCell>
                              <TableCell>{data.n}</TableCell>
                              <TableCell>{data.m}</TableCell>
                              <TableCell>{data.result}</TableCell>
                            </TableRow>
                          ))}
                        </React.Fragment>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={12}>
              <h3>Pathological Staging - Details :</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>lvsi</TableCell>
                      <TableCell>pni</TableCell>
                      <TableCell>margin</TableCell>
                      <TableCell>ki67</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {obj?.patient_observations?.staging_pathological_details?.map(
                      (row, index) => (
                        <React.Fragment key={index}>
                          {row.map((data) => (
                            <TableRow key={data.id}>
                              <TableCell>
                                {" "}
                                {data.date &&
                                  dayjs(data.date).format("DD-MM-YYYY")}
                              </TableCell>
                              <TableCell>{data.lvsi}</TableCell>
                              <TableCell>{data.pni}</TableCell>
                              <TableCell>{data.margin}</TableCell>
                              <TableCell>{data.ki67}</TableCell>
                            </TableRow>
                          ))}
                        </React.Fragment>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid item xs={12} md={12}>
              <h3>Molecular Pathology :</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Detail</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {obj?.patient_observations?.molecular_pathologies?.map(
                      (row, index) => (
                        <React.Fragment key={index}>
                          {row.map((data) => (
                            <TableRow key={data.id}>
                              <TableCell>
                                {" "}
                                {data.date &&
                                  dayjs(data.date).format("DD-MM-YYYY")}
                              </TableCell>
                              <TableCell>{data.detail}</TableCell>
                            </TableRow>
                          ))}
                        </React.Fragment>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={12}>
              <h3>Cancer Marker :</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell>Unit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {obj?.patient_observations?.cancer_markers?.map(
                      (row, index) => (
                        <React.Fragment key={index}>
                          {row.map((data) => (
                            <TableRow key={data.id}>
                              <TableCell>
                                {" "}
                                {data.date &&
                                  dayjs(data.date).format("DD-MM-YYYY")}
                              </TableCell>
                              <TableCell>{data.name}</TableCell>
                              <TableCell>{data.value}</TableCell>
                              <TableCell>{data.unit}</TableCell>
                            </TableRow>
                          ))}
                        </React.Fragment>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <h3>Chemotherapy Details :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Starting Date</TableCell>
                    <TableCell>Modality</TableCell>
                    <TableCell>LOT</TableCell>
                    <TableCell>Cycle No.</TableCell>
                    <TableCell>Protocol</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.chemotherapy?.map(
                    (row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                          </TableCell>

                          {/* <TableCell>
                            {row.chemotherapy_modalities?.length > 0 &&
                              row.chemotherapy_modalities
                                .map((item, i) => item.detail)
                                .join(" , ")}
                          </TableCell> */}
                          <TableCell>
                            {row.chemotherapy_modalities?.length > 0 &&
                              row.chemotherapy_modalities.map((item, i) => (
                                <p
                                  style={{
                                    borderBottom:
                                      "1px solid rgba(224, 224, 224, 1)",
                                    paddingBottom: "10px",
                                  }}
                                  key={i}
                                >
                                  {" "}
                                  {item.detail}
                                </p>
                              ))}
                          </TableCell>
                          <TableCell>{row.line_of_treatment}</TableCell>
                          {/* <TableCell>
                            {" "}
                            {row.chemotherapy_protocols?.length > 0 &&
                              row.chemotherapy_protocols
                                .map((item, i) => item.cycle_no)
                                .join(" , ")}
                          </TableCell> */}
                          <TableCell>
                            {row.chemotherapy_protocols?.length > 0 &&
                              row.chemotherapy_protocols.map((item, i) => (
                                <p
                                  style={{
                                    borderBottom:
                                      "1px solid rgba(224, 224, 224, 1)",
                                    paddingBottom: "10px",
                                  }}
                                  key={i}
                                >
                                  {" "}
                                  {item.cycle_no}
                                </p>
                              ))}
                          </TableCell>
                          <TableCell>
                            {renderProtocols(row.chemotherapy_protocols)}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                  {/* {obj?.patient_observations?.chemotherapy?.map(
                    (row, index) => (
                      <React.Fragment key={index}>
                        {row.map((data) => (
                          <TableRow key={data.id}>
                            <TableCell>
                              {" "}
                              {data.date &&
                                dayjs(data.date).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell>{data.cycle_no}</TableCell>
                            <TableCell>{renderProtocols(row)}</TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    )
                  )} */}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <h3>Surgery :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Surgery Date</TableCell>
                    <TableCell>Modality</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.surgeries?.map((row, index) => (
                    <React.Fragment key={index}>
                      {row.map((data) => (
                        <TableRow key={data.id}>
                          <TableCell>
                            {" "}
                            {data.surgery_date &&
                              dayjs(data.surgery_date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>{data.modality}</TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Radiotherapy Schedules :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Intent</TableCell>
                    <TableCell>Detail</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.radiotherapy_schedules?.map(
                    (row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {" "}
                          {row.start_date &&
                            dayjs(row.start_date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>
                          {" "}
                          {row.end_date &&
                            dayjs(row.end_date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>{row.intent}</TableCell>
                        <TableCell>
                          {row.detail?.length > 0 &&
                            row.detail.map((item, i) => item.value).join(" , ")}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Clinical Response Rate:</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Target lesion</TableCell>
                    <TableCell>Non target lesion</TableCell>
                    <TableCell>New Lasion</TableCell>
                    <TableCell>Response Rate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.clinical_response_rate?.map(
                    (row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>
                            {row.clinical_response_rate_target_lasion}
                          </TableCell>
                          <TableCell>
                            {row.clinical_response_rate_non_target_lasion}
                          </TableCell>
                          <TableCell>
                            {row.clinical_response_rate_non_target_lasion}
                          </TableCell>
                          <TableCell>
                            {row.clinical_response_rate_result}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Pathological Response Rate:</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Target lesion</TableCell>
                    <TableCell>Non target lesion</TableCell>
                    <TableCell>New Lasion</TableCell>
                    <TableCell>Response Rate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.pathological_response_rate?.map(
                    (row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>
                            {row.pathological_response_rate_target_lasion}
                          </TableCell>
                          <TableCell>
                            {row.pathological_response_rate_non_target_lasion}
                          </TableCell>
                          <TableCell>
                            {row.pathological_response_rate_non_target_lasion}
                          </TableCell>
                          <TableCell>
                            {row.pathological_response_rate_result}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Radiological Response Rate:</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Target lesion</TableCell>
                    <TableCell>Non target lesion</TableCell>
                    <TableCell>New Lasion</TableCell>
                    <TableCell>Response Rate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.radiological_response_rate?.map(
                    (row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>
                            {row.radiological_response_rate_target_lasion}
                          </TableCell>
                          <TableCell>
                            {row.radiological_response_rate_non_target_lasion}
                          </TableCell>
                          <TableCell>
                            {row.radiological_response_rate_non_target_lasion}
                          </TableCell>
                          <TableCell>
                            {row.radiological_response_rate_result}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <h3>Disease Progression Status :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Disease Progression Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.disease_progression_status?.map(
                    (row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>
                            {row.disease_progression_status}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Survival Status :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Survival Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.patient_observations?.survival_status?.map(
                    (row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>{row.survival_status}</TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Details;
