import {
  Box,
  Button,
  Collapse,
  IconButton,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import adminAPi from "../../../utils/adminAPi";
import Pagination from "@mui/material/Pagination";
import { debounce, isEmpty } from "lodash";
import { FeTextField } from "../../FormElements";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Checkbox from "@mui/material/Checkbox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import FeDesktopDatePicker from "../../FormElements/FeDesktopDatePicker";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PoList = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [list, setlist] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [checkedIds, setCheckedIds] = useState([]);
  const [page, setPage] = useState(1);
  const [value, setValue] = React.useState(0);

  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
    from: null,
    to: null,
  });

  useEffect(() => {
    getList(params);
    return () => {};
  }, [params]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getList = async (params) => {
    try {
      const { data } = await adminAPi.get(
        "admin/patients-with-observation",
        params
      );
      setlist(data.data.data);
      setTotalPage(data.data.last_page);
      setPage(data.data.current_page);
    } catch (error) {
      console.log("EE", error.response);
    }
  };
  const deleteItem = async (id) => {
    try {
      const { data } = await adminAPi.post(`admin/patient-observations/${id}`);
      getList();
    } catch (error) {
      console.log("EE", error.response);
    }
  };
  const downloadExel = async (ids, from, to) => {
    let objToPost = { ids, from, to };
    try {
      const { data } = await adminAPi.post(
        `admin/download-patient-observations`,
        objToPost,
        "blob"
      );

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      getList();
    } catch (error) {
      console.log("EE", error.response);
    }
  };
  const handleSearch = debounce((value) => {
    setParams({ ...params, search: value });
  }, 500);

  console.log("CHCEKCKED", list);
  const Row = ({ row, type }) => {
    const [open, setOpen] = React.useState(false);

    return (
      <>
        <TableRow>
          <TableCell>
            {" "}
            <Checkbox
              key={params.page}
              icon={icon}
              checkedIcon={checkedIcon}
              checked={checkedIds.includes(row.id)}
              style={{ marginRight: 8 }}
              onChange={(e) => {
                if (e.target.checked) {
                  setCheckedIds([...checkedIds, row.id]);
                } else {
                  setCheckedIds(checkedIds.filter((o) => o !== row.id));
                }
              }}
              // checked={selected}
            />
          </TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>

          <TableCell>{row.name}</TableCell>
          <TableCell>{row.phone}</TableCell>
          <TableCell>{row.date_of_birth}</TableCell>
          <TableCell>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="success"
                onClick={() => navigate(`${pathname}/details/${row.id}`)}
              >
                View
              </Button>
              {/* <Button
                variant="outlined"
                color="error"
                onClick={() => deleteItem(row.id)}
              >
                Delete
              </Button> */}
            </Stack>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Current Chemo Protocol</TableCell>
                      <TableCell align="right">Chemo Starting Date</TableCell>
                      <TableCell align="right">Chemo Cycle No</TableCell>
                      <TableCell align="right">Chemo Detail</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.patient_observations?.length > 0 &&
                      row.patient_observations.map((p, pID) => (
                        <TableRow key={pID}>
                          {" "}
                          <TableCell component="th" scope="row">
                            {p.current_chemo_protocol}
                          </TableCell>
                          <TableCell align="right">
                            {p.chemo_starting_date}
                          </TableCell>
                          <TableCell align="right">
                            {p.chemo_cycle_no}
                          </TableCell>
                          <TableCell align="right">{p.chemo_detail}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            indicatorColor="secondary"
            textColor="inherit"
          >
            <Tab label="Breast" {...a11yProps(0)} />
            {/* <Tab label="Lung" {...a11yProps(1)} /> */}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box display="flex" justifyContent="flex-end" m={1}>
            <FeTextField
              onChange={(e) => handleSearch(e.target.value)}
              label="Search"
            />
            <Button
              onClick={() => navigate(`${pathname}/breast/add`)}
              variant="contained"
            >
              Add
            </Button>
          </Box>

          <Grid container spacing={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item sm={8}>
                <FeDesktopDatePicker
                  label="From"
                  inputFormat="DD/MM/YYYY"
                  value={params.from}
                  onChange={(val) => {
                    setParams({
                      ...params,
                      from: dayjs(val).format("YYYY-MM-DD"),
                    });
                    // handleChangeInput(
                    //   "chemo_starting_date",
                    //   dayjs(val).format("YYYY-MM-DD")
                    // );
                  }}
                />
                <FeDesktopDatePicker
                  label="To"
                  inputFormat="DD/MM/YYYY"
                  value={params.to}
                  onChange={(val) => {
                    setParams({
                      ...params,
                      to: dayjs(val).format("YYYY-MM-DD"),
                    });

                    // handleChangeInput(
                    //   "chemo_starting_date",
                    //   dayjs(val).format("YYYY-MM-DD")
                    // );
                  }}
                />
              </Grid>
              <Grid item sm={4}>
                <Box display="flex" justifyContent="flex-end" m={1}>
                  <Button
                    onClick={() =>
                      downloadExel(checkedIds, params.from, params.to)
                    }
                    variant="contained"
                  >
                    Download
                  </Button>
                </Box>
              </Grid>
            </LocalizationProvider>
          </Grid>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>Patient Name</TableCell>
                  <TableCell>Patient Phone</TableCell>
                  <TableCell>Date of Birth</TableCell>

                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((row) => (
                  <Row key={row.name} row={row} type="breast" />
                ))}
              </TableBody>
            </Table>
            <Pagination
              count={totalPage}
              page={page}
              onChange={(e, v) => {
                setPage(v);
                setParams({ ...params, page: v });
              }}
            />
          </TableContainer>
        </CustomTabPanel>
        {/* <CustomTabPanel value={value} index={1}>
          <Box display="flex" justifyContent="flex-end" m={1}>
            <FeTextField
              onChange={(e) => handleSearch(e.target.value)}
              label="Search"
            />
            <Button
              onClick={() => navigate(`${pathname}/lung/add`)}
              variant="contained"
            >
              Add
            </Button>
          </Box>

          <Grid container spacing={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item sm={8}>
                <FeDesktopDatePicker
                  label="From"
                  inputFormat="DD/MM/YYYY"
                  value={params.from}
                  onChange={(val) => {
                    setParams({
                      ...params,
                      from: dayjs(val).format("YYYY-MM-DD"),
                    });
                    // handleChangeInput(
                    //   "chemo_starting_date",
                    //   dayjs(val).format("YYYY-MM-DD")
                    // );
                  }}
                />
                <FeDesktopDatePicker
                  label="To"
                  inputFormat="DD/MM/YYYY"
                  value={params.to}
                  onChange={(val) => {
                    setParams({
                      ...params,
                      to: dayjs(val).format("YYYY-MM-DD"),
                    });

                    // handleChangeInput(
                    //   "chemo_starting_date",
                    //   dayjs(val).format("YYYY-MM-DD")
                    // );
                  }}
                />
              </Grid>
              <Grid item sm={4}>
                <Box display="flex" justifyContent="flex-end" m={1}>
                  <Button
                    onClick={() =>
                      downloadExel(checkedIds, params.from, params.to)
                    }
                    variant="contained"
                  >
                    Download
                  </Button>
                </Box>
              </Grid>
            </LocalizationProvider>
          </Grid>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>Patient Name</TableCell>
                  <TableCell>Patient Phone</TableCell>
                  <TableCell>Date of Birth</TableCell>

                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((row) => (
                  <Row key={row.name} row={row} type="lung" />
                ))}
              </TableBody>
            </Table>
            <Pagination
              count={totalPage}
              page={page}
              onChange={(e, v) => {
                setPage(v);
                setParams({ ...params, page: v });
              }}
            />
          </TableContainer>
        </CustomTabPanel> */}
      </Box>
    </>
  );
};

export default PoList;
